// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const IndexTemplate = ({ data }: Props) => {
  const {
    url: siteUrl,
    title: siteTitle,
    subtitle: siteSubtitle,
    keywords: siteKeywords,
    author: siteAuthor,
    twitter: siteTwitter
  } = useSiteMetadata();

  const { html: pageBody } = data.markdownRemark;
  const { title: pageTitle, description: pageDescription } = data.markdownRemark.frontmatter;
  const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;

  return (
    <Layout title={siteTitle} description={metaDescription} keywords={siteKeywords}
            author={siteAuthor.name} image={`${siteUrl}${siteAuthor.photo}`} twitter={siteTwitter}>
      <Sidebar isIndex={true} />
      <Page title={pageTitle} isIndex={true}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query IndexTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`;

export default IndexTemplate;
